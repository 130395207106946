import { TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from '../../../i18n';
import isValidEmail from 'helpers/is-valid-email/isValidEmail';
import isValidAlias from 'helpers/is-valid-alias/isValidAlias';
import isValidIp from 'helpers/is-valid-ip/isValidIp';
import isValidRefId from 'helpers/is-valid-refId/isValidRefId';
import isValidRefundAmount from 'helpers/is-valid-refund-amount/isValidRefundAmount';
import _debounce from 'lodash/debounce';
//types
import { TextFieldWrapperProps } from '../types';

const TextFieldWrapper: React.FC<TextFieldWrapperProps> = ({
    t,
    onChange,
    required,
    type,
    name,
    value,
    defaultValue,
    label,
    inputRef,
    InputProps,
    inputProps,
    className,
    disabled,
    maxLength,
    placeholder,
    variant,
    color,
    customError,
    asyncCustomError,
    hideOptional,
    tooltipText,
    multiline,
    rows,
    autoComplete,
    serverFieldError,
    autoFocus,
    InputLabelProps,
    FormHelperTextProps,
}) => {
    const [eventName, setEventName] = useState<string | undefined>('');
    const [errorText, setErrorText] = useState<string | undefined>('');
    const [customErrorText, setCustomErrorText] = useState<string | undefined>('');

    const asyncCustomErrorDebounced = useCallback(
        _debounce(async (resolve, value) => {
            /* istanbul ignore next */
            if (asyncCustomError) {
                const message = await asyncCustomError(value);
                resolve(message);
            }
        }, 500),
        [],
    );

    useEffect(() => {
        if (
            ['beneficiaryIban', 'bban'].includes(String(name)) &&
            !required &&
            errorText === t('common:required')
        ) {
            setErrorText('');
        }
    }, [required]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (maxLength) {
            if (event.target.value.length > maxLength) {
                return;
            }
        }

        if (event.target.name === 'refundReason' && event.target.value.length > 140) {
            return;
        }

        onChange!(event);

        if (required) {
            if (!event.target.value) {
                return setErrorText(t('common:required'));
            }
        }

        if (type === 'email') {
            if (!isValidEmail(event.target.value)) {
                return setErrorText(t('common:invalidEmail'));
            }
        }

        if (name === 'companyAlias') {
            if (event.target.value !== event.target.value.toLowerCase()) {
                return setErrorText(t('common:uppperCaseForbidden'));
            }
            if (!isValidAlias(event.target.value)) {
                return setErrorText(t('common:invalidDomain'));
            }
        }

        if (name === 'refundAmount') {
            if (parseFloat(event.target.value) === InputProps.inputProps.min) {
                return setErrorText(t('common:invalidZeroRefundAmount'));
            }
            if (
                !isValidRefundAmount(
                    parseFloat(event.target.value),
                    InputProps.inputProps.min,
                    parseFloat(InputProps.inputProps.max),
                )
            ) {
                return setErrorText(t('common:invalidRefundAmount'));
            }
        }

        if (name === 'refundReason') {
            const remainingLength = 140 - event.target.value.length;
            setEventName('refundReason');
            return setCustomErrorText(t('common:refundReasonWarning', { remainingLength }));
        }

        if (name === 'refId') {
            if (!isValidRefId(event.target.value)) {
                return setErrorText(t('common:invalidRefId'));
            }
        }

        if (name === 'ip') {
            if (!isValidIp(event.target.value)) {
                return setErrorText(t('common:invalidIp'));
            }
        }

        if (customError) {
            const errorText = customError(event.target.value);

            if (errorText) {
                return setErrorText(errorText);
            }
        }

        if (asyncCustomError) {
            handleAsyncCustomError(event.target.value);
            return;
        }

        setErrorText('');
    };

    useEffect(() => {
        setErrorText(serverFieldError);
    }, [serverFieldError]);

    const handleAsyncCustomError = async (value: string) => {
        new Promise((resolve) => {
            asyncCustomErrorDebounced(resolve, value);
        }).then((d) => {
            setErrorText(d as string);
        });
    };

    label = required ? (
        label
    ) : (
        <div>
            <span>{label}</span>
            <span className={'optionalField'}>&nbsp;{!hideOptional && t('common:optional')}</span>
        </div>
    );

    const textField = (
        <TextField
            name={name}
            value={value}
            defaultValue={defaultValue}
            type={type}
            label={label}
            inputRef={inputRef}
            error={!!errorText}
            helperText={eventName === 'refundReason' ? customErrorText : errorText}
            onChange={handleChange}
            InputProps={InputProps}
            inputProps={inputProps}
            className={className || name}
            disabled={disabled}
            placeholder={placeholder}
            variant={variant}
            color={color}
            multiline={multiline}
            rows={rows}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            InputLabelProps={InputLabelProps}
            FormHelperTextProps={FormHelperTextProps}
        />
    );

    if (tooltipText) {
        return (
            <Tooltip title={tooltipText} arrow>
                {textField}
            </Tooltip>
        );
    }

    return textField;
};

export default withTranslation()(TextFieldWrapper);
